import React from "react";

const Footer = () => {
  return (
    <div className="bg-primary-blue p-4 rounded-t-3xl flex items-center justify-center">
      <p className="text-white font-bold text-xs md:text-sm">
        This website is made with React, Tailwind CSS and Framer Motion.
      </p>
    </div>
  );
};

export default Footer;

import Home from "./pages/Home";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from "./firebase";

function App() {
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
  return <Home />;
}

export default App;
